import {ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {SettlementComponent} from './settlement.component';

@Injectable()
export class CanDeactivateSettlement<T extends SettlementComponent> implements CanDeactivate<T> {

    constructor() {
    }

    canDeactivate(
        component: T,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (component.settling) {
            return component.settlingBackActionCheck();
        }
        return true;
    }
}
